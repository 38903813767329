import React from "react"
import Layout from "../components/layout"
import { graphql, StaticQuery } from "gatsby"
import MetaPost from '../components/metapost'
import Metatags from "../components/Metatags"

const RoundupPost = () =>{
    return (
      <Layout className="landing-page-blackfriday">
        <StaticQuery
          query={EcommerceDealsQuery}
          render={data => {
            return (
              <>
                <div className="partners-select">
                  <Metatags
                    title="Optinly Black Friday and Cyber Monday Deals"
                    description= "Optinly's 2021 Black Friday deals are live. Get 50% off this sale!"
                    keywords={data.markdownRemark.frontmatter.keywords}
                    url={data.site.siteMetadata.siteUrl}
                    sitename={data.site.siteMetadata.title}
                    pathname={data.markdownRemark.frontmatter.path}
                  />
                  <MetaPost
                    excerptData={data.markdownRemark.htmlAst}
                    toc={data.markdownRemark.tableOfContents}
                  />
                </div>
              </>
            )
          }}
        />
      </Layout>
    )}

const EcommerceDealsQuery = graphql`
  query EcommerceDealsQuery {
    markdownRemark(frontmatter: { path: { eq: "/ecommerce-black-friday-deals" } }) {
      htmlAst
      frontmatter {
        description
        keywords
        title
        path
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`
export default RoundupPost